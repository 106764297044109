import { createClient } from "@supabase/supabase-js";

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
// const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;

export const supabaseClient = createClient(
  supabaseUrl,
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImJ6b2J0bGR1bGp4Ym15YWl2bGFhIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc2NDMxNjksImV4cCI6MjAxMzIxOTE2OX0.vxxYluT9nGKQi_QHFaye1UkHE7Kooy633-sRoRuMUJg",
  {
    db: {
      schema: "public",
    },
  }
);
